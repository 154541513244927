import CardDropdown from 'components/common/CardDropdown';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { recentLeadsTableData } from 'data/dashboard/crm';
import React from 'react';
import { Card } from 'react-bootstrap';
import RecentLeadsTable from './RecentLeadsTable';

const RecentLeads = () => {
  return (
    <Card>
      <FalconCardHeader
        title="Recent Leads"
        titleTag="h6"
        className="py-2"
        endEl={<CardDropdown />}
      />
      <Card.Body className="py-0">
        <RecentLeadsTable data={recentLeadsTableData} />
      </Card.Body>
      <FalconCardFooterLink title="Show full list" size="sm" />
    </Card>
  );
};

export default RecentLeads;
