import React from 'react';
import { Form } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const EmployeeInformation = ({ employeeObject }) => {
  const [employee] = useState(employeeObject ? employeeObject : {});

  return (
    <>
      <FalconComponentCard>
        <FalconComponentCard.Header className="border-bottom" noPreview>
          <Flex justifyContent="between" alignItems="center">
            <h5 className="mb-0 hover-actions-trigger" id="geplandDrukwerk">
              Medewerker Gegevens
            </h5>
          </Flex>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <Form noValidate>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="name">
                <Form.Label>Naam</Form.Label>
                <Form.Control
                  value={employee.name}
                  type="text"
                  required
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een familienaam op te geven
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="firstName">
                <Form.Label>Voornaam</Form.Label>
                <Form.Control
                  value={employee.firstName}
                  type="text"
                  required
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een voornaam op te geven
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="name">
                <Form.Label>Afkorting</Form.Label>
                <Form.Control
                  value={employee.afkorting}
                  type="text"
                  required
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een afkorting op te geven
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Card.Title as="div">Adres</Card.Title>
            <Row className="mb-3 g-3">
              <Form.Group as={Col}>
                <Form.Label>Adres</Form.Label>
                <Form.Control
                  value={employee.adres}
                  type="text"
                  required
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een adres op te geven
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Card.Title as="div">Contact</Card.Title>
            <Row className="mb-3 g-3">
              <Form.Group as={Col}>
                <Form.Label>Thuistelefoon</Form.Label>
                <Form.Control
                  value={employee.phoneHome}
                  type="text"
                  required
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een telefoonnummer op te geven
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Mobieletelefoon</Form.Label>
                <Form.Control
                  value={employee.phoneMobile}
                  type="text"
                  required
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een mobieletelefoon op te geven
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>E-mail Privé</Form.Label>
                <Form.Control
                  value={employee.emailPrivate}
                  type="text"
                  required
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een e-mail op te geven
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>E-mail School</Form.Label>
                <Form.Control
                  value={employee.emailCloud}
                  type="text"
                  required
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een email op te geven
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Form.Group>
              <Flex justifyContent="start" alignItems="center">
                <Button variant="warning" className="mt-3  me-3">
                  Genereer Wachtwoord
                </Button>
              </Flex>
            </Form.Group>
          </Form>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default EmployeeInformation;
