import React from 'react';
import PrintWorkQueueTable from '../components/PrintWorkQueueTable';

const PrintWorkQueue = ({ name }) => {
  return (
    <>
      <PrintWorkQueueTable name={name} />
    </>
  );
};

export default PrintWorkQueue;
