import React from 'react';
import { Form } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';
import Button from 'react-bootstrap/Button';
import SchoolService from 'services/school.service';
import { Table } from 'react-bootstrap';
import ActionButton from 'components/common/ActionButton';

const SchoolInstitutionNumbers = ({ schoolObject }) => {
  const [institutionNumber, setInstitutionNumber] = useState({});
  const [institutionNumbers] = useState(
    schoolObject ? schoolObject.institutionNumbers : []
  );
  const [validated, setValidated] = useState(false);

  const onChangeName = e => {
    setInstitutionNumber({
      ...institutionNumber,
      name: e.target.value
    });
  };

  const onChangeInstNr = e => {
    setInstitutionNumber({
      ...institutionNumber,
      instNr: e.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    setValidated(true);

    SchoolService.registerInstitutionNumber(
      schoolObject.id,
      institutionNumber
    ).then(
      () => {
        window.location.reload();
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.error) ||
          error.error ||
          error.toString();
        console.log(resMessage);
      }
    );
  };

  const deleteInstitutionNumber = instId => {
    SchoolService.deleteInstitutionNumber(schoolObject.id, instId).then(
      () => {
        window.location.reload();
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.error) ||
          error.error ||
          error.toString();
        console.log(resMessage);
      }
    );
  };

  return (
    <>
      <FalconComponentCard>
        <FalconComponentCard.Header className="border-bottom" noPreview>
          <Flex justifyContent="between" alignItems="center">
            <h5 className="mb-0 hover-actions-trigger" id="geplandDrukwerk">
              Instellingsnummers
            </h5>
          </Flex>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="align-items-center g-3">
              <Form.Group as={Col} controlId="name">
                <Form.Label>Naam</Form.Label>
                <Form.Control
                  value={institutionNumber.name}
                  type="text"
                  required
                  onChange={onChangeName}
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een naam op te geven
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="instNr">
                <Form.Label>Instellingsnummer</Form.Label>
                <Form.Control
                  value={institutionNumber.instNr}
                  type="text"
                  required
                  onChange={onChangeInstNr}
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een instellingsnummer op te geven
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="submit">
                <Button type="submit" color="primary" className="mt-4 me-3">
                  Maak
                </Button>
              </Form.Group>
            </Row>
          </Form>

          <Table responsive>
            <thead>
              <tr>
                <th scope="col">Naam</th>
                <th scope="col">Instellingsnummers</th>
                <th className="text-end" scope="col">
                  Acties
                </th>
              </tr>
            </thead>
            <tbody>
              {institutionNumbers && (
                <>
                  {institutionNumbers.map(instNr => (
                    <tr key={instNr.id}>
                      <td>{instNr.name}</td>
                      <td>{instNr.instNr}</td>
                      <td className="text-end">
                        <ActionButton
                          icon="trash-alt"
                          title="Verwijderen"
                          variant="action"
                          className="p-0"
                          onClick={() => deleteInstitutionNumber(instNr.id)}
                        />
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </Table>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default SchoolInstitutionNumbers;
