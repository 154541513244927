import React from 'react';
import { Form } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';
import Button from 'react-bootstrap/Button';
import { Tab, Tabs } from 'react-bootstrap';
import IconAlert from 'components/common/IconAlert';
import SchoolService from 'services/school.service';
import AuthService from 'services/auth.service';
import DrukwerkService from 'services/drukwerk.service';
import DatePicker from 'react-datepicker';
import PrintWorkStudentGroup from '../components/PrintWorkStudentGroup';
import CourseService from 'services/course.service';
import { useDropzone } from 'react-dropzone';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';

function UploadZone() {
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: 'application/pdf'
    });

  const files = acceptedFiles.map(file => (
    <p key={file.path} className="fs-0 mb-0 text-700">
      {file.path}
    </p>
  ));

  let rejectedFiles = fileRejections.map(({ file }) => (
    <IconAlert key={file.path} variant="warning">
      <p className="mb-0">{file.path} is geen PDF</p>
    </IconAlert>
  ));

  return (
    <>
      {rejectedFiles}
      <div
        {...getRootProps({ className: 'dropzone-area py-6' })}
        style={{
          marginBottom: 20
        }}
      >
        <input {...getInputProps()} />
        <Flex justifyContent="center">
          <img src={cloudUpload} alt="" width={25} className="me-2" />
          {acceptedFiles.length > 0 ? (
            files
          ) : (
            <p className="fs-0 mb-0 text-700">Upload je bestanden hier</p>
          )}
        </Flex>
      </div>
    </>
  );
}

function DrukOpties() {
  const organizerOptions = [
    { value: '1', label: 'Recto-verso' },
    { value: '2', label: 'Sorteren' },
    { value: '3', label: 'Nieten' },
    { value: '4', label: 'Perforeren' }
  ];
  const [value, setValue] = useState(organizerOptions.slice(0, 2));
  return (
    <Select
      closeMenuOnSelect={false}
      options={organizerOptions}
      placeholder="Select..."
      isMulti
      classNamePrefix="react-select"
      value={value}
      onChange={value => setValue(value)}
    />
  );
}

const NewPrint = () => {
  const minDate = new Date(
    new Date().setTime(new Date().getTime() + 1 * 86400000)
  );
  const today = new Date();

  const categories = [
    {
      name: 'Mijn Klassen',
      eventKey: '1',
      id: 1,
      klassen: [
        {
          name: 'Persoonlijk',
          students: []
        }
      ]
    },
    {
      name: '1ste Jaars',
      eventKey: '2',
      id: 2,
      klassen: []
    },
    {
      name: '2de Jaars',
      eventKey: '3',
      id: 3,
      klassen: []
    },
    {
      name: '3de Jaars',
      eventKey: '4',
      id: 4,
      klassen: []
    },
    {
      name: '4de Jaars',
      eventKey: '5',
      id: 5,
      klassen: []
    },
    {
      name: '5de Jaars',
      eventKey: '6',
      id: 6,
      klassen: []
    },
    {
      name: '6de Jaars',
      id: 7,
      eventKey: '7',
      klassen: []
    },
    {
      name: '7de Jaars',
      id: 8,
      eventKey: '8',
      klassen: []
    }
  ];

  const soortenDrukwerk = [
    { value: 0, label: 'Gewoon Drukwerk' },
    { value: 1, label: 'Toets / Taak' },
    { value: 2, label: 'Gewoon Examen' },
    { value: 3, label: 'Examen op voorhand' }
  ];

  const hulpmiddelen = [
    { value: 0, label: 'Geen Hulpmiddel (wit)' },
    { value: 1, label: 'Rekenmachine (groen)' },
    { value: 2, label: 'Taaltaak (blauw)' },
    { value: 3, label: 'Ander Hulpmiddel (geel)' }
  ];

  const [show, setShow] = useState(false);
  const [employee, setEmployee] = useState();
  const [employees, setEmployees] = useState();
  const [date, setDate] = useState(minDate);
  const [studentgroups, setStudentGroups] = useState([]);
  const [courses, setCourses] = useState();
  const [course, setCourse] = useState();
  const [soortDrukwerk, setSoortDrukwerk] = useState(0);
  const [hulpmiddel, setHulpmiddel] = useState(0);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    SchoolService.getEmployees(user.schoolId).then(data => {
      let transformed = data
        .map(employee => ({
          value: employee.id,
          sapRef: employee.sapRef,
          afkorting: employee.afkorting,
          label:
            employee.firstName +
            ' ' +
            employee.name +
            ' - ' +
            employee.afkorting
        }))
        .sort((a, b) => (a.afkorting > b.afkorting ? 1 : -1));

      setEmployees(transformed);
      setEmployee(transformed.find(employee => employee.sapRef == user.sapRef));
    });

    CourseService.getCourses().then(data => {
      let transformed = data.map(course => ({
        value: course.name,
        label: course.name
      }));

      setCourses(transformed);
    });

    async function fetchStudentGroups() {
      var finalCategories = [];

      for (const category of categories) {
        await DrukwerkService.getStudentGroups(category.id).then(data => {
          category.klassen = category.klassen.concat(data);
          finalCategories = finalCategories.concat(category);
          setStudentGroups(finalCategories);
        });
      }
    }

    fetchStudentGroups();
  }, []);

  return (
    <>
      {show && (
        <IconAlert variant="success" dismissible onClose={() => setShow(false)}>
          <p className="mb-0">Drukwerk is succesvol verzonden!</p>
        </IconAlert>
      )}
      <FalconComponentCard>
        <FalconComponentCard.Header className="border-bottom" noPreview>
          <Flex justifyContent="between" alignItems="center">
            <h5 className="mb-0 hover-actions-trigger" id="geplandDrukwerk">
              Nieuw Drukwerk
            </h5>
          </Flex>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body noLight className="py-0" noInline>
          <Form>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formUser">
                <Form.Label>Naam</Form.Label>
                <Select
                  closeMenuOnSelect={true}
                  options={employees}
                  placeholder="Select..."
                  classNamePrefix="react-select"
                  value={employee}
                  onChange={value => setEmployee(value)}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formAfgifte">
                <Form.Label>Datum Afgifte</Form.Label>
                <DatePicker
                  formatWeekDay={day => day.slice(0, 3)}
                  className="form-control"
                  dateFormat="dd-MM-yyyy"
                  selected={today}
                  minDate={today}
                  maxDate={today}
                  placeholderText="Kies een datum"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formTerug">
                <Form.Label>Datum Terug</Form.Label>
                <DatePicker
                  selected={date}
                  onChange={date => setDate(date)}
                  formatWeekDay={day => day.slice(0, 3)}
                  className="form-control"
                  dateFormat="dd-MM-yyyy"
                  placeholderText="Kies een datum"
                />
              </Form.Group>
            </Row>

            <Tabs defaultActiveKey="1" id="uncontrolled-tab-example">
              {studentgroups.map(category => (
                <Tab
                  key={category.eventKey}
                  eventKey={category.eventKey}
                  title={category.name}
                  className="border-bottom border-x p-3"
                >
                  {category.klassen.map(klas => (
                    <PrintWorkStudentGroup
                      key={klas.name}
                      groupName={klas.name}
                      leerlingen={klas.students}
                    />
                  ))}
                </Tab>
              ))}
            </Tabs>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formAfgifte">
                <Form.Label>Aantal Exemplaren</Form.Label>
                <Form.Control type="number" placeholder="2" disabled />
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="check">
                <Form.Label>Soort Drukwerk</Form.Label>
                <Select
                  closeMenuOnSelect={true}
                  options={soortenDrukwerk}
                  placeholder="Select..."
                  classNamePrefix="react-select"
                  value={soortenDrukwerk[soortDrukwerk]}
                  onChange={value => setSoortDrukwerk(value.value)}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="check">
                <Form.Label>Drukopties</Form.Label>
                <DrukOpties />
              </Form.Group>
            </Row>

            {soortDrukwerk >= 2 && (
              <Row className="mb-3 g-3">
                <Form.Group as={Col} controlId="check">
                  <Form.Label>Vak</Form.Label>
                  <Select
                    closeMenuOnSelect={true}
                    options={courses}
                    placeholder="Select..."
                    classNamePrefix="react-select"
                    value={courses[course]}
                    onChange={value => setCourse(value.value)}
                    afkorting
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="check">
                  <Form.Label>Hulpmiddelen</Form.Label>
                  <Select
                    closeMenuOnSelect={true}
                    afkorting
                    options={hulpmiddelen}
                    placeholder="Select..."
                    classNamePrefix="react-select"
                    value={hulpmiddelen[hulpmiddel]}
                    onChange={value => setHulpmiddel(value.value)}
                  />
                </Form.Group>
              </Row>
            )}

            <Form.Group
              as={Col}
              controlId="opmerkingen"
              style={{
                marginBottom: 20
              }}
            >
              <Form.Label>Opmerkingen</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>

            <UploadZone />

            <Button
              onClick={() => setShow(true)}
              variant="primary"
              style={{
                marginBottom: 20
              }}
            >
              Versturen
            </Button>
          </Form>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default NewPrint;
