import React, { useState, useEffect } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';
import { Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import ActionButton from 'components/common/ActionButton';

import SchoolService from 'services/school.service';
import AuthService from 'services/auth.service';
import { Link } from 'react-router-dom';

const Employees = () => {
  const [employees, setEmployees] = useState();
  const [searchQuery, setSearchQuery] = useState();

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    SchoolService.getEmployees(user.schoolId).then(data => {
      setEmployees(data);
    });
  }, []);

  const onSubmitSearch = event => {
    event.preventDefault();
  };

  return (
    <FalconComponentCard>
      <FalconComponentCard.Header className="border-bottom" noPreview>
        <Flex justifyContent="start" alignItems="center">
          <h5 className="mb-0 hover-actions-trigger" id="geplandDrukwerk">
            Medewerkers
          </h5>
        </Flex>
      </FalconComponentCard.Header>
      <FalconComponentCard.Body>
        <Form className="mb-4" onSubmit={onSubmitSearch}>
          <Row className="align-items-center g-3">
            <Form.Group as={Col} controlId="name">
              <Form.Control
                type="text"
                placeholder="Zoek een leerling..."
                name="search"
                value={searchQuery}
                onInput={e => setSearchQuery(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group as={Col} controlId="submit">
              <Button type="submit" color="primary" className="me-3">
                Zoeken
              </Button>
            </Form.Group>
          </Row>
        </Form>

        <Table responsive>
          <thead>
            <tr>
              <th scope="col">Naam</th>
              <th scope="col">Afkorting</th>
              <th scope="col">E-mail School</th>
              <th scope="col">E-mail Privé</th>
              <th className="text-end" scope="col">
                Acties
              </th>
            </tr>
          </thead>
          <tbody>
            {employees && (
              <>
                {employees
                  .filter(
                    employee =>
                      !searchQuery ||
                      (employee.firstName + employee.name)
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      employee.afkorting
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      (employee.firstName + ' ' + employee.name)
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      employee.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                  )
                  .sort((a, b) => (a.afkorting > b.afkorting ? 1 : -1))
                  .map(employee => (
                    <tr key={employee.id}>
                      <td>
                        {employee.name} {employee.firstName}
                      </td>
                      <td>{employee.afkorting}</td>
                      <td>{employee.emailCloud}</td>
                      <td>{employee.emailPrivate}</td>
                      <td className="text-end">
                        <Link to={'/beheer/employee/' + employee.id}>
                          <ActionButton
                            icon="search"
                            title="Details"
                            variant="action"
                            className="p-0"
                          />
                        </Link>
                      </td>
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </Table>
      </FalconComponentCard.Body>
    </FalconComponentCard>
  );
};

export default Employees;
