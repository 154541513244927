import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/employee';

class EmployeeService {
  async getEmployee(employeeId) {
    const response = await axios.get(API_URL + '/' + employeeId, {
      headers: authHeader()
    });
    return await response.data;
  }
}

export default new EmployeeService();
