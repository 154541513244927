import React, { useEffect } from 'react';
import { useState } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';
import StudentGroupService from 'services/studentgroup.service';
import ActionButton from 'components/common/ActionButton';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const StudentGroupStudents = ({ studentGroupObject }) => {
  const [students, setStudents] = useState([]);
  -useEffect(() => {
    if (studentGroupObject.id) {
      StudentGroupService.getStudentsByStudentGroup(studentGroupObject.id).then(
        data => {
          setStudents(data);
        }
      );
    }
  }, []);

  return (
    <>
      <FalconComponentCard>
        <FalconComponentCard.Header className="border-bottom" noPreview>
          <Flex justifyContent="between" alignItems="center">
            <h5 className="mb-0 hover-actions-trigger" id="geplandDrukwerk">
              Leerlingen
            </h5>
          </Flex>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <Table responsive>
            <thead>
              <tr>
                <th scope="col">Naam</th>
                <th scope="col">E-mail</th>
                <th scope="col">Verjaardag</th>
                <th scope="col">Klasnummer</th>
                <th className="text-end" scope="col">
                  Acties
                </th>
              </tr>
            </thead>
            <tbody>
              {students && (
                <>
                  {students
                    .sort((a, b) => (a.groupNr > b.groupNr ? 1 : -1))
                    .map(student => (
                      <tr key={student.id}>
                        <td>
                          {student.name} {student.firstName}
                        </td>
                        <td>{student.emailCloud}</td>
                        <td>{student.birthDate.split('T')[0]}</td>
                        <td>{student.groupNr}</td>
                        <td className="text-end">
                          <Link to={'/beheer/student/' + student.id}>
                            <ActionButton
                              icon="search"
                              title="Details"
                              variant="action"
                              className="p-0"
                            />
                          </Link>
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          </Table>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default StudentGroupStudents;
