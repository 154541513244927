import { Table } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconComponentCard from 'components/common/FalconComponentCard';
import ActionButton from 'components/common/ActionButton';
import Flex from 'components/common/Flex';

const TableRow = ({ data }) => (
  <tr className="align-middle">
    <td className="text-nowrap">{data.filename}</td>
    <td className="text-nowrap">{data.afgifte}</td>
    <td className="text-nowrap">{data.terug}</td>
    <td className="text-nowrap">{data.aantal}</td>
    <td>
      <SoftBadge pill bg={data.status.type}>
        {data.status.title}
        <FontAwesomeIcon icon={data.status.icon} className="ms-2" />
      </SoftBadge>
    </td>
    <td className="text-end">
      <ActionButton
        icon="search"
        title="Bekijken"
        variant="action"
        className="p-0 me-2"
      />
    </td>
  </tr>
);

const PrintWorkPendingTable = ({ name }) => {
  const printData = [
    {
      filename: 'Toets Engels.pdf',
      afgifte: '17/11/2021',
      terug: '21/11/2021',
      aantal: '15',
      status: { title: 'In Wachtrij', type: 'warning', icon: 'redo' }
    },
    {
      filename: 'Toets Nederlands.pdf',
      afgifte: '13/11/2021',
      terug: '17/11/2021',
      aantal: '15',
      status: { title: 'In Wachtrij', type: 'warning', icon: 'redo' }
    }
  ];

  return (
    <FalconComponentCard>
      <FalconComponentCard.Header className="border-bottom" noPreview>
        <Flex justifyContent="between" alignItems="center">
          <h5 className="mb-0 hover-actions-trigger" id="geplandDrukwerk">
            {name}
          </h5>
        </Flex>
      </FalconComponentCard.Header>
      <FalconComponentCard.Body noLight className="py-0" noInline>
        <Table responsive striped hover>
          <thead>
            <tr>
              <th scope="col">Bestands Naam</th>
              <th scope="col">Afgifte Datum</th>
              <th scope="col">Terug Datum</th>
              <th scope="col">Aantal</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {printData.map(print => (
              <TableRow data={print} key={print.filename} />
            ))}
          </tbody>
        </Table>
      </FalconComponentCard.Body>
    </FalconComponentCard>
  );
};

export default PrintWorkPendingTable;
