import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FalconComponentCard from 'components/common/FalconComponentCard';
import PageHeader from 'components/common/PageHeader';
import createMarkup from 'helpers/createMarkup';

const Plugins = () => {
  const [plugins] = useState([
    {
      title: 'Dayjs',
      description: `<strong>Day.js</strong> is a 2KB immutable date-time library alternative to Moment.js
      with the same modern API.`,
      docLink: 'https://day.js.org/docs/en/installation/installation'
    },
    {
      title: 'Echarts For React',
      description: ` Falcon-React uses <strong>echarts-for-react</strong> for chart component.
      <strong> echarts-for-react </strong>
      is the simplest, and the best React wrapper for
      <a href="https://echarts.apache.org/en/index.html" target="_blank">
        Apache ECharts
      </a>`,
      docLink: 'https://github.com/hustcc/echarts-for-react'
    },
    {
      title: 'Fuse.js',
      description: `<strong>Fuse.js</strong> is a powerful, lightweight fuzzy-search library, with zero dependencies.`,
      docLink: 'https://fusejs.io/'
    },

    {
      title: 'React Datepicker',
      description: `A simple and reusable Datepicker component for React.`,
      docLink: 'https://github.com/Hacker0x01/react-datepicker'
    },

    {
      title: 'React Font Awesome',
      description: `Get vector icons and social logos on your website with <strong>React Font Awesome</strong> , the web's
      most popular icon set and toolkit.`,
      docLink:
        'https://fontawesome.com/v5.15/how-to-use/on-the-web/using-with/react'
    },

    {
      title: 'React Hook Form',
      description: `Performant, flexible and extensible forms with easy-to-use validation.`,
      docLink: 'https://react-hook-form.com/'
    },

    {
      title: 'React Router Hash Link',
      description: `When you click on a link created with <code>react-router-hash-link</code> it will scroll to the element on the page with the <code>id</code> that matches the <code>#hash-fragment</code> in the link.`,
      docLink: 'https://github.com/rafgraph/react-router-hash-link'
    },
    {
      title: 'Is.js',
      description: `Check types, regexps, presence, time and more...`,
      docLink: 'http://is.js.org/'
    },
    {
      title: 'React Lottie',
      description: `Render After Effects animations natively on Web, Android, and iOS, and React Native.`,
      docLink: 'https://github.com/chenqingspring/react-lottie'
    }
  ]);
  return (
    <>
      <PageHeader title="Plugins" className="mb-3">
        <p className="mt-2 mb-0">
          Falcon-React uses some third-party library to make it more flexible
          and robust.Here is the list of all plugins:
        </p>
      </PageHeader>

      {plugins.map(plugin => (
        <PluginCard
          key={plugin.title}
          title={plugin.title}
          description={plugin.description}
          themeLink={plugin.themeLink}
          docLink={plugin.docLink}
        />
      ))}
    </>
  );
};

const PluginCard = ({ title, description, docLink }) => (
  <FalconComponentCard>
    <FalconComponentCard.Header title={title} light={false} noPreview />
    <FalconComponentCard.Body>
      <p dangerouslySetInnerHTML={createMarkup(description)} />
      <a href={docLink} target="_blank" rel="noreferrer">
        Full Documentation
      </a>
    </FalconComponentCard.Body>
  </FalconComponentCard>
);

PluginCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  docLink: PropTypes.string.isRequired
};

export default Plugins;
