import React from 'react';
import { Form } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';
import Button from 'react-bootstrap/Button';
import { Card } from 'react-bootstrap';
import StudentService from 'services/student.service';

const StudentInformation = ({ studentObject }) => {
  const [student] = useState(studentObject ? studentObject : {});
  const [isLoading, setLoading] = useState(false);

  const changePassword = event => {
    setLoading(true);
    event.preventDefault();

    StudentService.changePassword(student.id).then(
      response => {
        setLoading(false);

        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.href = fileURL;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.error) ||
          error.error ||
          error.toString();
        console.log(resMessage);
      }
    );
  };

  return (
    <>
      <FalconComponentCard>
        <FalconComponentCard.Header className="border-bottom" noPreview>
          <Flex justifyContent="between" alignItems="center">
            <h5 className="mb-0 hover-actions-trigger" id="geplandDrukwerk">
              Leerling Gegevens
            </h5>
          </Flex>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <Form noValidate>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="name">
                <Form.Label>Naam</Form.Label>
                <Form.Control
                  value={student.name}
                  type="text"
                  required
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een familienaam op te geven
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="name">
                <Form.Label>Voornaam</Form.Label>
                <Form.Control
                  value={student.firstName}
                  type="text"
                  required
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een voornaam op te geven
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            {/*  */}
            <Card.Title as="div">Adres</Card.Title>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="name">
                <Form.Label>Straat</Form.Label>
                <Form.Control
                  value={student.street}
                  type="text"
                  required
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een straat op te geven
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="name">
                <Form.Label>Huisnummer</Form.Label>
                <Form.Control
                  value={student.streetNr}
                  type="text"
                  required
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een huisnummer op te geven
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            {/*  */}
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="name">
                <Form.Label>Postcode</Form.Label>
                <Form.Control
                  value={student.postalCode}
                  type="text"
                  required
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een postcode op te geven
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="name">
                <Form.Label>Gemeente</Form.Label>
                <Form.Control
                  value={student.community}
                  type="text"
                  required
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een gemeente op te geven
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="name">
                <Form.Label>Land</Form.Label>
                <Form.Control
                  value={student.country}
                  type="text"
                  required
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een land op te geven
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            {/*  */}
            <Card.Title as="div">Contact</Card.Title>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="name">
                <Form.Label>Telefoon Thuis</Form.Label>
                <Form.Control
                  value={student.phoneHome}
                  type="text"
                  required
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een thuistelefoon op te geven
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="name">
                <Form.Label>Mobiel</Form.Label>
                <Form.Control
                  value={student.phoneMobile}
                  type="text"
                  required
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een mobieletelefoon op te geven
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            {/*  */}
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="name">
                <Form.Label>E-mail Privé</Form.Label>
                <Form.Control
                  value={student.emailPrivate}
                  type="text"
                  required
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een e-mail op te geven
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="name">
                <Form.Label>E-mail School</Form.Label>
                <Form.Control
                  value={student.emailCloud}
                  type="text"
                  required
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een e-mail op te geven
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Form.Group>
              <Flex justifyContent="start" alignItems="center">
                {student.id && (
                  <Button
                    variant="warning"
                    className="mt-3  me-3"
                    disabled={isLoading}
                    onClick={!isLoading ? changePassword : null}
                  >
                    {isLoading ? 'Laden...' : 'Genereer Wachtwoord'}
                  </Button>
                )}
              </Flex>
            </Form.Group>
          </Form>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default StudentInformation;
