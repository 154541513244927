import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/timetable';

class RoomService {
  async getTimeTable(timeTableId) {
    const response = await axios.get(API_URL + '/' + timeTableId, {
      headers: authHeader()
    });
    return await response.data;
  }

  async getTimeTablesByStudentGroup(studentGroupId) {
    const response = await axios.get(
      API_URL + '/studentgroup/' + studentGroupId,
      {
        headers: authHeader()
      }
    );
    return await response.data;
  }

  async getTimeTablesByEmployee(employeeId) {
    const response = await axios.get(API_URL + '/employee/' + employeeId, {
      headers: authHeader()
    });
    return await response.data;
  }

  async getTimeTables() {
    const response = await axios.get(API_URL, {
      headers: authHeader()
    });
    return await response.data;
  }
}

export default new RoomService();
