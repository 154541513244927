import React, { useEffect } from 'react';
import { useState } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';
import TimeTableService from 'services/timetable.service';
import SoftBadge from 'components/common/SoftBadge';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const EmployeeTimeTables = ({ employeeObject }) => {
  const [timeTables, setTimeTables] = useState([]);
  -useEffect(() => {
    if (employeeObject.id) {
      TimeTableService.getTimeTablesByEmployee(employeeObject.id).then(data => {
        setTimeTables(data);
      });
    }
  }, []);

  return (
    <>
      <FalconComponentCard>
        <FalconComponentCard.Header className="border-bottom" noPreview>
          <Flex justifyContent="between" alignItems="center">
            <h5 className="mb-0 hover-actions-trigger" id="geplandDrukwerk">
              Uurrooster
            </h5>
          </Flex>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <Table responsive>
            <thead>
              <tr>
                <th scope="col" className="text-center">
                  Uur
                </th>
                <th scope="col" className="text-center">
                  Maandag
                </th>
                <th scope="col" className="text-center">
                  Dinsdag
                </th>
                <th scope="col" className="text-center">
                  Woensdag
                </th>
                <th scope="col" className="text-center">
                  Donderdag
                </th>
                <th scope="col" className="text-center">
                  Vrijdag
                </th>
              </tr>
            </thead>
            <tbody>
              {Array(8)
                .fill(1)
                .map((el, i) => (
                  <tr key={i}>
                    <td className="text-center">
                      <h3>{i + 1}</h3>
                    </td>
                    {timeTables.map(timeTable => (
                      <>
                        {timeTable.length > i && timeTable[i] != null ? (
                          <td key={timeTable[i].id} className="text-center">
                            <Flex direction="column">
                              <SoftBadge pill bg="primary" className="me-2">
                                {timeTable[i].courseName}
                              </SoftBadge>
                              <Link
                                to={
                                  '/beheer/studentgroup/' +
                                  timeTable[i].studentGroupId
                                }
                                replace
                              >
                                <div>{timeTable[i].studentGroupCode}</div>
                              </Link>
                              {timeTable[i].rooms.map(room => (
                                <div key={room.roomId}>
                                  {room.roomName}
                                  {' ('}
                                  {room.roomSystemName}
                                  {')'}
                                </div>
                              ))}
                            </Flex>
                          </td>
                        ) : (
                          <td className="text-center">
                            <Flex direction="column">
                              <SoftBadge pill bg="danger" className="me-2">
                                Geen Les
                              </SoftBadge>
                            </Flex>
                          </td>
                        )}
                      </>
                    ))}
                  </tr>
                ))}
            </tbody>
          </Table>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default EmployeeTimeTables;
