import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { ProgressBar, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { useTable, useSortBy, usePagination } from 'react-table';
import classNames from 'classnames';

const AdvanceTable = ({ columns, data }) => {
  const { getTableProps, headers, page, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 3 }
    },
    useSortBy,
    usePagination
  );

  return (
    <div className="mx-ncard mt-3">
      <SimpleBarReact>
        <Table className="fs--1 mb-0" {...getTableProps()}>
          <thead className="bg-200 text-800">
            <tr>
              {headers.map((column, index) => (
                <th
                  key={index}
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({
                      ...column.headerProps,
                      className: classNames('text-nowrap', {
                        [column.headerProps?.className]:
                          column.headerProps?.className
                      })
                    })
                  )}
                >
                  {column.render('Header')}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <span className="sort desc" />
                    ) : (
                      <span className="sort asc" />
                    )
                  ) : (
                    <span className="sort" />
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        {...cell.getCellProps({
                          className: cell.column.className
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </SimpleBarReact>
    </div>
  );
};

const LocationBySessionTable = ({ data }) => {
  const columns = [
    {
      accessor: 'country',
      Header: 'Country',
      Cell: rowData => (
        <Link to="#!">
          <Flex alignItems="center">
            <img src={rowData.data[rowData.row.index].flag} alt="..." />
            <p className="mb-0 ps-3 country text-700">
              {rowData.data[rowData.row.index].country}
            </p>
          </Flex>
        </Link>
      )
    },
    {
      accessor: 'sessions',
      Header: 'Sessions',
      className: 'fw-semi-bold'
    },
    {
      accessor: 'users',
      Header: 'Users'
    },
    {
      accessor: 'percentage',
      Header: 'Percentage',
      headerClassName: 'text-end',
      Cell: rowData => (
        <Flex alignItems="center" justifyContent="end">
          <p className="mb-0 me-2">
            {rowData.data[rowData.row.index].percentage}%
          </p>
          <ProgressBar
            now={rowData.data[rowData.row.index].percentage}
            style={{ height: '0.3125rem', width: '3.8rem' }}
          />
        </Flex>
      )
    }
  ];
  return <AdvanceTable data={data} columns={columns} />;
};

AdvanceTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired
};

LocationBySessionTable.propTypes = {
  data: PropTypes.array.isRequired
};

export default LocationBySessionTable;
