import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SchoolInformation from './components/SchoolInformation';
import SchoolService from 'services/school.service';
import SchoolInstitutionNumbers from './components/SchoolInstitutionNumbers';

const School = () => {
  const { id } = useParams();

  const [school, setSchool] = useState();

  useEffect(() => {
    if (id) {
      SchoolService.getSchool(id).then(data => {
        setSchool(data);
      });
    }
  }, []);

  console.log(id);

  return (
    <>
      {id && school && (
        <>
          <SchoolInformation schoolObject={school} />
          <SchoolInstitutionNumbers schoolObject={school} />
        </>
      )}

      {!id && !school && (
        <>
          <SchoolInformation />
        </>
      )}
    </>
  );
};

export default School;
