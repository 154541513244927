import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import StudentGroupInformation from './components/StudentGroupInformation';
import StudentGroupStudents from './components/StudentGroupStudents';
import StudentgroupService from 'services/studentgroup.service';
import StudentGroupTeachers from './components/StudentGroupTeachers';
import StudentGroupTimeTables from './components/StudentGroupTimeTables';

const StudentGroup = () => {
  const { id } = useParams();
  const [studentGroup, setStudentGroup] = useState();

  useEffect(() => {
    StudentgroupService.getStudentGroup(id).then(data => {
      setStudentGroup(data);
    });
  }, []);

  return (
    <>
      {id && studentGroup && (
        <>
          <StudentGroupInformation studentGroupObject={studentGroup} />
          <StudentGroupStudents studentGroupObject={studentGroup} />
          <StudentGroupTeachers studentGroupObject={studentGroup} />
          <StudentGroupTimeTables studentGroupObject={studentGroup} />
        </>
      )}
    </>
  );
};

export default StudentGroup;
