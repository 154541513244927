import React, { useState, useEffect } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';
import { Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import SchoolService from 'services/school.service';
import AuthService from 'services/auth.service';

const StudentGroups = () => {
  const [studentGroups, setStudentGroups] = useState();
  const [searchQuery, setSearchQuery] = useState();

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    SchoolService.getStudentGroups(user.schoolId).then(data => {
      setStudentGroups(data);
    });
  }, []);

  const onSubmitSearch = event => {
    event.preventDefault();
  };

  return (
    <FalconComponentCard>
      <FalconComponentCard.Header className="border-bottom" noPreview>
        <Flex justifyContent="start" alignItems="center">
          <h5 className="mb-0 hover-actions-trigger" id="geplandDrukwerk">
            Klassen
          </h5>
        </Flex>
      </FalconComponentCard.Header>
      <FalconComponentCard.Body>
        <Form className="mb-4" onSubmit={onSubmitSearch}>
          <Row className="align-items-center g-3">
            <Form.Group as={Col} controlId="name">
              <Form.Control
                type="text"
                placeholder="Zoek een klas..."
                name="search"
                value={searchQuery}
                onInput={e => setSearchQuery(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group as={Col} controlId="submit">
              <Button type="submit" color="primary" className="me-3">
                Zoeken
              </Button>
            </Form.Group>
          </Row>
        </Form>

        <Row className="g-4">
          {studentGroups && (
            <>
              {studentGroups
                .filter(
                  studentGroup =>
                    !searchQuery ||
                    studentGroup.code
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                )
                .map(studentGroup => (
                  <Col sm={6} lg={3} key={studentGroup.code}>
                    <Card
                      bg="primary"
                      key={studentGroup.code}
                      text="white"
                      //onClick={() => deleteSchool(school)}
                    >
                      <Card.Body>
                        <Card.Title as="div">{studentGroup.code}</Card.Title>
                        <Card.Text>{studentGroup.name}</Card.Text>
                        <Link
                          to={'/beheer/studentgroup/' + studentGroup.id}
                          replace
                        >
                          <Button variant="light" className="me-2 mb-1">
                            Meer Info
                          </Button>
                        </Link>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
            </>
          )}
        </Row>
      </FalconComponentCard.Body>
    </FalconComponentCard>
  );
};

export default StudentGroups;
