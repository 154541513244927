import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useState } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';
import StudentGroupService from 'services/studentgroup.service';
import ActionButton from 'components/common/ActionButton';
import { Link } from 'react-router-dom';

const StudentGroupTeachers = ({ studentGroupObject }) => {
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    if (studentGroupObject.id) {
      StudentGroupService.getEmployeesByStudentGroup(
        studentGroupObject.id
      ).then(data => {
        setEmployees(data);
      });
    }
  }, []);

  return (
    <>
      <FalconComponentCard>
        <FalconComponentCard.Header className="border-bottom" noPreview>
          <Flex justifyContent="between" alignItems="center">
            <h5 className="mb-0 hover-actions-trigger" id="geplandDrukwerk">
              Leerkrachten
            </h5>
          </Flex>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <Table responsive>
            <thead>
              <tr>
                <th scope="col">Naam</th>
                <th scope="col">E-mail</th>
                <th scope="col">Afkorting</th>
                <th className="text-end" scope="col">
                  Acties
                </th>
              </tr>
            </thead>
            <tbody>
              {employees && (
                <>
                  {employees
                    .sort((a, b) => (a.afkorting > b.afkorting ? 1 : -1))
                    .map(employee => (
                      <tr key={employee.id}>
                        <td>
                          {employee.name} {employee.firstName}
                        </td>
                        <td>{employee.emailCloud}</td>
                        <td>{employee.afkorting}</td>
                        <td className="text-end">
                          <Link to={'/beheer/employee/' + employee.id}>
                            <ActionButton
                              icon="search"
                              title="Details"
                              variant="action"
                              className="p-0"
                            />
                          </Link>
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          </Table>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default StudentGroupTeachers;
