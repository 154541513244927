import { Route, Redirect } from 'react-router-dom';
import authService from 'services/auth.service';
export { PrivateRoute };

function PrivateRoute({ component: Component, ...rest }) {
  const auth = authService.getCurrentUser();
  return (
    <Route
      {...rest}
      render={props => {
        if (!auth) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
}
