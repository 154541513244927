import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/school';

class SchoolService {
  async registerOrUpdateSchool(school) {
    const response = await axios.put(API_URL, school, {
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json'
      }
    });
    return await response.data;
  }

  async registerInstitutionNumber(schoolId, institutionNumber) {
    const response = await axios.put(
      API_URL + '/' + schoolId + '/institutionnumbers',
      institutionNumber,
      {
        headers: {
          ...authHeader(),
          'Content-Type': 'application/json'
        }
      }
    );
    return await response.data;
  }

  async getSchool(schoolId) {
    const response = await axios.get(API_URL + '/' + schoolId, {
      headers: authHeader()
    });
    return await response.data;
  }

  async getStudentGroups(schoolId) {
    const response = await axios.get(
      API_URL + '/' + schoolId + '/studentgroups',
      { headers: authHeader() }
    );
    return await response.data;
  }

  async getStudents(schoolId) {
    const response = await axios.get(API_URL + '/' + schoolId + '/students', {
      headers: authHeader()
    });
    return await response.data;
  }

  async getRooms(schoolId) {
    const response = await axios.get(API_URL + '/' + schoolId + '/rooms', {
      headers: authHeader()
    });
    return await response.data;
  }

  async getEmployees(schoolId) {
    const response = await axios.get(API_URL + '/' + schoolId + '/employees', {
      headers: authHeader()
    });
    return await response.data;
  }

  async deleteInstitutionNumber(schoolId, instId) {
    const response = await axios.delete(
      API_URL + '/' + schoolId + '/institutionnumbers',
      { params: { schoolId, instId }, headers: authHeader() }
    );
    return await response.data;
  }

  async deleteSchool(schoolId) {
    const response = await axios.delete(API_URL + '/' + schoolId, {
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json'
      }
    });
    return await response.data;
  }
}

export default new SchoolService();
