import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/studentgroup';

class StudentGroupService {
  async getStudentGroup(studentGroupId) {
    const response = await axios.get(API_URL + '/' + studentGroupId, {
      headers: authHeader()
    });
    return await response.data;
  }

  async getStudentsByStudentGroup(studentGroupId) {
    const response = await axios.get(
      API_URL + '/' + studentGroupId + '/students',
      { headers: authHeader() }
    );
    return await response.data;
  }

  async getEmployeesByStudentGroup(studentGroupId) {
    const response = await axios.get(
      API_URL + '/' + studentGroupId + '/employees',
      { headers: authHeader() }
    );
    return await response.data;
  }

  async changePasswords(studentGroupId) {
    const response = await axios.get(
      API_URL + '/' + studentGroupId + '/changepasswords',
      {
        headers: {
          ...authHeader(),
          'Content-Type': 'application/pdf'
        },
        responseType: 'blob'
      }
    );
    return await response;
  }
}

export default new StudentGroupService();
