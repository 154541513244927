import React from 'react';
import PrintWorkHistoryTable from '../components/PrintWorkHistoryTable';
import PrintWorkPendingTable from '../components/PrintWorkPendingTable';

const MyPrintWork = () => {
  return (
    <>
      <PrintWorkPendingTable />
      <PrintWorkHistoryTable />
    </>
  );
};

export default MyPrintWork;
