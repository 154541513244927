import { Route, Redirect } from 'react-router-dom';
import authService from 'services/auth.service';
export { LoginRoute };

function LoginRoute({ component: Component, ...rest }) {
  const auth = authService.getCurrentUser();
  return (
    <Route
      {...rest}
      render={props => {
        if (auth) {
          return (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
}
