import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/course';

class CourseService {
  async getCourses() {
    const response = await axios.get(API_URL + '/all', {
      headers: authHeader()
    });
    return await response.data;
  }
}

export default new CourseService();
