import React, { useState, useEffect } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';
import { Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IconButton from 'components/common/IconButton';

import SchoolBoardService from 'services/schoolboard.service';

const SchoolBoard = () => {
  const [schoolBoard, setSchoolBoard] = useState();
  const [schools, setSchools] = useState();
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    SchoolBoardService.getSchoolBoard().then(data => {
      setSchoolBoard(data);

      SchoolBoardService.getSchools(data.id).then(schoolData => {
        setSchools(schoolData);
      });
    });
  }, []);

  const onChangeName = e => {
    setSchoolBoard({
      ...schoolBoard,
      name: e.target.value
    });
  };

  const onChangeInstNr = e => {
    setSchoolBoard({
      ...schoolBoard,
      instNr: e.target.value
    });
  };

  const onChangeStreet = e => {
    setSchoolBoard({
      ...schoolBoard,
      street: e.target.value
    });
  };

  const onChangeStreetNr = e => {
    setSchoolBoard({
      ...schoolBoard,
      streetNr: e.target.value
    });
  };

  const onChangePostalCode = e => {
    setSchoolBoard({
      ...schoolBoard,
      postalCode: e.target.value
    });
  };

  const onChangeCommunity = e => {
    setSchoolBoard({
      ...schoolBoard,
      community: e.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    setValidated(true);

    SchoolBoardService.registerOrUpdateSchoolBoard(schoolBoard).then(
      () => {
        window.location.reload();
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.error) ||
          error.error ||
          error.toString();
        console.log(resMessage);
      }
    );
  };

  return (
    <>
      {schoolBoard && (
        <>
          <FalconComponentCard>
            <FalconComponentCard.Header className="border-bottom" noPreview>
              <Flex justifyContent="start" alignItems="center">
                <h5 className="mb-0 hover-actions-trigger" id="geplandDrukwerk">
                  Schoolbestuur Gegevens
                </h5>
              </Flex>
            </FalconComponentCard.Header>
            <FalconComponentCard.Body>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col}>
                    <Form.Label>Naam</Form.Label>
                    <Form.Control
                      value={schoolBoard.name}
                      required
                      type="text"
                      onChange={onChangeName}
                    />
                    <Form.Control.Feedback type="invalid">
                      Gelieve een naam op te geven
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>Instellingsnummer</Form.Label>
                    <Form.Control
                      value={schoolBoard.instNr}
                      required
                      type="text"
                      onChange={onChangeInstNr}
                    />
                    <Form.Control.Feedback type="invalid">
                      Gelieve een instellingsnummer op te geven
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Card.Title as="div">Adres</Card.Title>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col}>
                    <Form.Label>Straat</Form.Label>
                    <Form.Control
                      value={schoolBoard.street}
                      required
                      type="text"
                      onChange={onChangeStreet}
                    />
                    <Form.Control.Feedback type="invalid">
                      Gelieve een straat op te geven
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>Huisnummer</Form.Label>
                    <Form.Control
                      value={schoolBoard.streetNr}
                      required
                      type="text"
                      onChange={onChangeStreetNr}
                    />
                    <Form.Control.Feedback type="invalid">
                      Gelieve een huisnummer op te geven
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>Postcode</Form.Label>
                    <Form.Control
                      value={schoolBoard.postalCode}
                      required
                      type="text"
                      onChange={onChangePostalCode}
                    />
                    <Form.Control.Feedback type="invalid">
                      Gelieve een postcode op te geven
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>Gemeente</Form.Label>
                    <Form.Control
                      value={schoolBoard.community}
                      required
                      type="text"
                      onChange={onChangeCommunity}
                    />
                    <Form.Control.Feedback type="invalid">
                      Gelieve een gemeente op te geven
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Form.Group style={{ paddingBottom: '20px' }}>
                  <Flex justifyContent="start" alignItems="center">
                    <Button type="submit" color="primary" className="mt-3">
                      Bewaren
                    </Button>
                  </Flex>
                </Form.Group>
              </Form>
            </FalconComponentCard.Body>
          </FalconComponentCard>
          <FalconComponentCard>
            <FalconComponentCard.Header className="border-bottom" noPreview>
              <Flex justifyContent="between" alignItems="center">
                <h5 className="mb-0 hover-actions-trigger" id="geplandDrukwerk">
                  Scholen
                </h5>
                <Link to="/beheer/school/new">
                  <IconButton
                    variant="outline-primary"
                    className="mb-1"
                    icon="plus"
                    transform="shrink-3"
                  >
                    Nieuwe School
                  </IconButton>
                </Link>
              </Flex>
            </FalconComponentCard.Header>
            <FalconComponentCard.Body>
              <Row className="g-4">
                {schools && (
                  <>
                    {schools.map(school => (
                      <Col sm={6} lg={4} key={school.name}>
                        <Card
                          bg="primary"
                          key={school.name}
                          text="white"
                          //onClick={() => deleteSchool(school)}
                        >
                          <Card.Body>
                            <Card.Title as="div">{school.name}</Card.Title>
                            <Card.Text
                              style={{
                                whiteSpace: 'pre'
                              }}
                            >
                              {school.street} {school.streetNr}
                              {'\n'}
                              {school.postalCode} {school.community}
                            </Card.Text>
                            <Link to={'/beheer/school/' + school.id}>
                              <Button variant="light" className="me-2 mb-1">
                                Meer Info
                              </Button>
                            </Link>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </>
                )}
              </Row>
            </FalconComponentCard.Body>
          </FalconComponentCard>
        </>
      )}

      {/* Information About Group */}
      {/* Information School Boards inside the group */}
    </>
  );
};

export default SchoolBoard;
