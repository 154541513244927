import React from 'react';
import { Button } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconComponentCard from 'components/common/FalconComponentCard';
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter
} from 'react-table';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';

const data = `const columns = [
  {
    accessor: 'name',
    Header: 'Name'
  },
  {
    accessor: 'email',
    Header: 'Email'
  },
  {
    accessor: 'age',
    Header: 'Age'
  }
];

const data = [
  {
    name: 'Anna',
    email: 'anna@example.com',
    age: 18
  },
  {
    name: 'Homer',
    email: 'homer@example.com',
    age: 35
  },
  {
    name: 'Oscar',
    email: 'oscar@example.com',
    age: 52
  },
  {
    name: 'Emily',
    email: 'emily@example.com',
    age: 30
  },
  {
    name: 'Jara',
    email: 'jara@example.com',
    age: 25
  },
  {
    name: 'Clark',
    email: 'clark@example.com',
    age: 39
  },
  {
    name: 'Jennifer',
    email: 'jennifer@example.com',
    age: 52
  },
  {
    name: 'Tony',
    email: 'tony@example.com',
    age: 30
  },
  {
    name: 'Tom',
    email: 'tom@example.com',
    age: 25
  },
  {
    name: 'Michael',
    email: 'michael@example.com',
    age: 39
  },
  {
    name: 'Antony',
    email: 'antony@example.com',
    age: 39
  },
  {
    name: 'Raymond',
    email: 'raymond@example.com',
    age: 52
  },
  {
    name: 'Marie',
    email: 'marie@example.com',
    age: 30
  },
  {
    name: 'Cohen',
    email: 'cohen@example.com',
    age: 25
  },
  {
    name: 'Rowen',
    email: 'rowen@example.com',
    age: 39
  }
];`;

const exampleCode = `function AdvanceTable({ columns, data, size }) {
  const [showAll, setShowAll] = useState(false);
  const {
    getTableProps,
    headers,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: size }
    },
    useSortBy,
    usePagination
  );

  const handlePageSize = () => {
    setPageSize(showAll ? size : data.length);
    setShowAll(!showAll);
  };

  return (
    <>
      <Table
        responsive
        bordered
        striped
        {...getTableProps()}
        className="fs--1 mb-0"
      >
        <thead className="bg-200 text-900">
          <tr>
            {headers.map((column, index) => (
              <th
                key={index}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <span className="sort desc" />
                  ) : (
                    <span className="sort asc" />
                  )
                ) : (
                  <span className="sort" />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <AdvanceTableFooter
        page={page}
        pageSize={pageSize}
        pageIndex={pageIndex}
        rowCount={data.length}
        setPageSize={setPageSize}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        previousPage={previousPage}
      />
    </>
  );
}


function AdvanceTableExample() {
  ${data}
  return (
    <AdvanceTable columns={columns} data={data} size={5} />
  );
}

render(<AdvanceTableExample />)
`;

const paginationNumberingCode = `function AdvanceTable({ columns, data, size }) {
  const {
    getTableProps,
    headers,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    pageCount,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: size }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <Table
        responsive
        bordered
        striped
        {...getTableProps()}
        className="fs--1 mb-0"
      >
        <thead className="bg-200 text-900">
          <tr>
            {headers.map((column, index) => (
              <th
                key={index}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <span className="sort desc" />
                  ) : (
                    <span className="sort asc" />
                  )
                ) : (
                  <span className="sort" />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <AdvanceTablePagination
        pageIndex={pageIndex}
        pageCount={pageCount}
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        previousPage={previousPage}
      />
    </>
  );
}
function AdvanceTableExample() {
  ${data}
  return (
    <AdvanceTable columns={columns} data={data} size={5} />
  );
}

render(<AdvanceTableExample />)`;

const searchableTableCode = `function AdvanceTable({ columns, data, size }) {
  const {
    getTableProps,
    headers,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: size }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Row className="flex-end-center">
        <Col xs="auto" sm={6} lg={4}>
          <AdvanceTableSearchBox
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Col>
      </Row>
      <Table
        responsive
        bordered
        striped
        {...getTableProps()}
        className="fs--1 mb-0 mt-3"
      >
        <thead className="bg-200 text-900">
          <tr>
            {headers.map((column, index) => (
              <th
                key={index}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <span className="sort desc" />
                  ) : (
                    <span className="sort asc" />
                  )
                ) : (
                  <span className="sort" />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {page.length === 0 && (
        <div className="text-center">
          <p className="fw-bold fs-1 mt-3">No data found</p>
        </div>
      )}
      <AdvanceTableFooter
        page={page}
        pageSize={pageSize}
        pageIndex={pageIndex}
        rowCount={data.length}
        setPageSize={setPageSize}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        previousPage={previousPage}
      />
      
    </>
  );
}

function AdvanceTableExample() {
  ${data}
  return <AdvanceTable columns={columns} data={data} size={5} />;
}

render(<AdvanceTableExample />)`;

const AdvanceTable = () => (
  <>
    <PageHeader
      title="Advance Tables"
      description={`React-Falcon uses <strong>React Table</strong> for advance features of table. React Table is a collection of hooks for <strong>building powerful tables and datagrid experiences</strong>. These hooks are lightweight, composable, and ultra-extensible, but <strong>do not render any markup or styles for you</strong>. This effectively means that React Table is a "headless" UI library"`}
      className="mb-3"
    >
      <Button
        href="https://react-table.tanstack.com/docs/overview"
        target="_blank"
        variant="link"
        size="sm"
        className="ps-0"
      >
        React Table Documentation
        <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
      </Button>
    </PageHeader>

    <FalconComponentCard>
      <FalconComponentCard.Header title="Example" light={false} />
      <FalconComponentCard.Body
        code={exampleCode}
        scope={{
          useTable,
          usePagination,
          useSortBy,
          AdvanceTableFooter
        }}
        language="jsx"
        noInline
        noLight
      />
    </FalconComponentCard>

    <FalconComponentCard>
      <FalconComponentCard.Header
        title="Pagination with numbering"
        light={false}
      />
      <FalconComponentCard.Body
        code={paginationNumberingCode}
        scope={{
          useTable,
          usePagination,
          useSortBy,
          AdvanceTablePagination
        }}
        language="jsx"
        noInline
        noLight
      />
    </FalconComponentCard>

    <FalconComponentCard>
      <FalconComponentCard.Header
        title="Searchable Table"
        light={false}
        className="border-bottom border-200"
      />
      <FalconComponentCard.Body
        code={searchableTableCode}
        scope={{
          useTable,
          usePagination,
          useSortBy,
          useGlobalFilter,
          AdvanceTableSearchBox,
          AdvanceTableFooter
        }}
        language="jsx"
        noInline
        noLight
      />
    </FalconComponentCard>
  </>
);

export default AdvanceTable;
