import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import logo from '../../../assets/img/smartschool.png';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  return (
    <>
      <Row className="g-4">
        {[
          {
            variant: 'light',
            image: logo
          },
          {
            name: 'Inhaaltoetsen',
            variant: 'success',
            description: 'Planning van de inhaaltoetsen in Google Drive.'
          }
        ].map((item, idx) => (
          <Col sm={6} lg={3} key={item.variant}>
            <Link
              to={{
                pathname: 'https://olvp.smartschool.be'
              }}
              target="_blank"
            >
              <Card
                bg={item.variant.toLowerCase()}
                key={idx}
                text={item.variant.toLowerCase() === 'light' ? 'dark' : 'white'}
              >
                <Card.Body>
                  {item.image && <Card.Img src={item.image} variant="top" />}
                  {item.name && <Card.Title as="div">{item.name}</Card.Title>}
                  {item.description && (
                    <Card.Text>{item.description}</Card.Text>
                  )}
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Dashboard;
