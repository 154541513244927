import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
import Select from 'react-select';

import AuthService from '../../services/auth.service';
import SchoolBoardService from '../../services/schoolboard.service';

const LoginForm = () => {
  // State
  const history = useHistory();

  const [formData, setFormData] = useState({
    username: '',
    password: '',
    school: '',
    loading: false,
    message: ''
  });

  const [schools, setSchools] = useState();
  useEffect(() => {
    SchoolBoardService.getLoginSchools().then(
      data => {
        setSchools(data);
        setFormData({
          ...formData,
          school: data[0]
        });
      },
      error => {
        setFormData({
          ...formData,
          loading: false,
          message: error.response.data.message
        });
      }
    );
  }, []);

  const onChangeUsername = e => {
    setFormData({
      ...formData,
      username: e.target.value
    });
  };

  const onChangePassword = e => {
    setFormData({
      ...formData,
      password: e.target.value
    });
  };

  const onChangeSchool = school => {
    setFormData({
      ...formData,
      school: school
    });
  };

  const handleLogin = e => {
    e.preventDefault();

    setFormData({
      ...formData,
      message: '',
      loading: true
    });

    AuthService.login(
      formData.school.value,
      formData.username,
      formData.password
    ).then(
      data => {
        localStorage.setItem('user', JSON.stringify(data));
        history.push('/');
        window.location.reload();
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.error ||
          error.toString();

        setFormData({
          ...formData,
          loading: false,
          message: resMessage
        });
      }
    );
  };

  return (
    <Form onSubmit={handleLogin}>
      {schools && schools.length > 1 && (
        <Form.Group className="mb-3">
          <Form.Label>School</Form.Label>
          <Select
            closeMenuOnSelect={true}
            options={schools}
            placeholder="Selecteer een school"
            classNamePrefix="react-select"
            value={formData.school}
            onChange={onChangeSchool}
          />
        </Form.Group>
      )}

      <Form.Group className="mb-3">
        <Form.Label>Gebruikersnaam</Form.Label>
        <Form.Control
          value={formData.username}
          name="text"
          onChange={onChangeUsername}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Wachtwoord</Form.Label>
        <Form.Control
          value={formData.password}
          name="password"
          onChange={onChangePassword}
          type="password"
        />
      </Form.Group>

      <Form.Group style={{ paddingBottom: '20px' }}>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={
            !formData.username || !formData.password || formData.loading
          }
        >
          Log in
        </Button>
      </Form.Group>

      {formData.message && (
        <Alert key="login-error" variant="danger">
          {formData.message}
        </Alert>
      )}
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
