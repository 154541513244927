import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EmployeeService from 'services/employee.service';
import EmployeeInformation from './components/EmployeeInformation';
import EmployeeTimeTables from './components/EmployeeTimeTables';

const Employee = () => {
  const { id } = useParams();
  const [employee, setEmployee] = useState();

  useEffect(() => {
    EmployeeService.getEmployee(id).then(data => {
      setEmployee(data);
    });
  }, []);

  return (
    <>
      {id && employee && (
        <>
          <EmployeeInformation employeeObject={employee} />
          <EmployeeTimeTables employeeObject={employee} />
        </>
      )}
    </>
  );
};

export default Employee;
