import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';
import ErrorLayout from './ErrorLayout';
import Login from 'components/authentication/Login';
import Logout from 'components/authentication/Logout';
import { PrivateRoute } from 'routes/PrivateRoute';
import { LoginRoute } from 'routes/LoginRoute';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Switch>
        <Route path="/errors" component={ErrorLayout} />
        <LoginRoute path="/login" exact component={Login} />
        <Route path="/logout" exact component={Logout} />
        <PrivateRoute component={MainLayout} />
        <Redirect to="/errors/404" />
      </Switch>
    </>
  );
};

export default Layout;
