import React, { useState, useEffect } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';
import { Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import ActionButton from 'components/common/ActionButton';
import { Link } from 'react-router-dom';
import IconButton from 'components/common/IconButton';

import SchoolService from 'services/school.service';
import AuthService from 'services/auth.service';

const Rooms = () => {
  const [rooms, setRooms] = useState();
  const [searchQuery, setSearchQuery] = useState();

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    SchoolService.getRooms(user.schoolId).then(data => {
      setRooms(data);
    });
  }, []);

  const onSubmitSearch = event => {
    event.preventDefault();
  };

  return (
    <FalconComponentCard>
      <FalconComponentCard.Header className="border-bottom" noPreview>
        <Flex justifyContent="between" alignItems="center">
          <h5 className="mb-0 hover-actions-trigger" id="geplandDrukwerk">
            Lokalen
          </h5>
          <Link to="/beheer/rooms/new">
            <IconButton
              variant="outline-primary"
              className="mb-1"
              icon="plus"
              transform="shrink-3"
            >
              Nieuw Lokaal
            </IconButton>
          </Link>
        </Flex>
      </FalconComponentCard.Header>
      <FalconComponentCard.Body>
        <Form className="mb-4" onSubmit={onSubmitSearch}>
          <Row className="align-items-center g-3">
            <Form.Group as={Col} controlId="name">
              <Form.Control
                type="text"
                placeholder="Zoek een lokaal..."
                name="search"
                value={searchQuery}
                onInput={e => setSearchQuery(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group as={Col} controlId="submit">
              <Button type="submit" color="primary" className="me-3">
                Zoeken
              </Button>
            </Form.Group>
          </Row>
        </Form>

        <Table responsive>
          <thead>
            <tr>
              <th scope="col">Naam</th>
              <th scope="col">Systeemnaam</th>
              <th scope="col">Type</th>
              <th scope="col">Max Bezetting</th>
              <th className="text-end" scope="col">
                Acties
              </th>
            </tr>
          </thead>
          <tbody>
            {rooms && (
              <>
                {rooms
                  .filter(
                    room =>
                      !searchQuery ||
                      room.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      room.systemName
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      room.roomType
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                  )
                  .sort((a, b) => (a.systemName > b.systemName ? 1 : -1))
                  .map(student => (
                    <tr key={student.id}>
                      <td>{student.name}</td>
                      <td>{student.systemName}</td>
                      <td>
                        {student.roomType
                          .replace('_', ' ')
                          .split(' ')
                          .map(
                            i =>
                              i[0].toUpperCase() + i.substring(1).toLowerCase()
                          )
                          .join(' ')}
                      </td>
                      <td>{student.maxBezetting}</td>
                      <td className="text-end">
                        <ActionButton
                          icon="search"
                          title="Details"
                          variant="action"
                          className="p-0"
                        />
                      </td>
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </Table>
      </FalconComponentCard.Body>
    </FalconComponentCard>
  );
};

export default Rooms;
