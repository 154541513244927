import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/schoolboard';

class SchoolBoardService {
  async registerOrUpdateSchoolBoard(schoolBoard) {
    const response = await axios.put(API_URL, schoolBoard, {
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json'
      }
    });
    return await response.data;
  }

  async getLoginSchools() {
    console.log('request');
    var domain = window.location.hostname;

    const response = await axios.get(API_URL + '/login', {
      params: { domain }
    });
    return await response.data;
  }

  async getSchoolBoard() {
    const response = await axios.get(API_URL, { headers: authHeader() });
    return await response.data;
  }

  async getSchools(id) {
    console.log(id);
    const response = await axios.get(API_URL + '/schools', {
      params: { id },
      headers: authHeader()
    });
    return await response.data;
  }
}

export default new SchoolBoardService();
