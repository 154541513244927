import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/student';

class StudentService {
  async getStudent(studentId) {
    const response = await axios.get(API_URL + '/' + studentId, {
      headers: authHeader()
    });
    return await response.data;
  }

  async changePassword(studentId) {
    const response = await axios.get(
      API_URL + '/' + studentId + '/changepassword',
      {
        headers: {
          ...authHeader(),
          'Content-Type': 'application/pdf'
        },
        responseType: 'blob'
      }
    );
    return await response;
  }
}

export default new StudentService();
