import { SplitButton, Dropdown, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'components/bootstrap-components/ButtonGroup';
import { useState } from 'react';

const PrintWorkStudentGroup = ({ groupName, leerlingen }) => {
  const [selected, setSelected] = useState(false);
  const staticButton = leerlingen != undefined && leerlingen.length > 0;

  return (
    <>
      {staticButton ? (
        <SplitButton
          as={ButtonGroup}
          key={groupName}
          id={groupName}
          variant={selected ? 'primary' : 'outline-primary'}
          title={groupName}
          drop="down"
          className="me-2 mb-2"
          onClick={() => {
            setSelected(!selected);
          }}
        >
          <Dropdown.Header>Leerlingen</Dropdown.Header>

          {leerlingen
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(leerling => (
              <Form.Check
                key={leerling.name}
                className="dropdown-item"
                type="checkbox"
                id="defaultCheckbox"
                label={leerling.name + ' ' + leerling.firstname}
                defaultChecked
              />
            ))}
        </SplitButton>
      ) : (
        <Button
          key={groupName}
          id={groupName}
          variant={selected ? 'primary' : 'outline-primary'}
          displayName={groupName}
          className="me-2 mb-2"
          onClick={() => {
            setSelected(!selected);
          }}
        >
          {groupName}
        </Button>
      )}
    </>
  );
};

export default PrintWorkStudentGroup;
