import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/printwork';

class DrukwerkService {
  async getStudentGroups(id) {
    const response = await axios.get(API_URL + '/studentgroups/' + id, {
      headers: authHeader()
    });
    return await response.data;
  }
}

export default new DrukwerkService();
