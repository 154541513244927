import React, { useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import IconItem from 'components/common/icon/IconItem';
import StatsChart from './StatsChart';
import { getColor } from 'helpers/utils';
import FalconCardHeader from 'components/common/FalconCardHeader';
import DealForeCastTable from 'components/drukwerk/components/DealForeCastTable';
import TrafficSourceChart from 'components/drukwerk/components/TrafficSourceChart';

const StatsItem = ({ stat }) => {
  const { icon, color, title, amount, caret, caretColor, target, data } = stat;
  return (
    <>
      <Flex justifyContent="center" alignItems="center" className="mb-3">
        <IconItem
          tag="div"
          icon={icon}
          bg={`soft-${color}`}
          color={color}
          size="sm"
          iconClass="fs--2"
          className="me-2 shadow-none"
        />
        <h6 className="mb-0 flex-1">{title}</h6>
      </Flex>
      <Flex>
        <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">{amount}%</p>
        <div className="d-flex flex-column">
          <FontAwesomeIcon
            icon={caret}
            className={`me-1 mb-0 text-${caretColor}`}
          />
          <p className="fs--2 mb-0 mt-0 text-nowrap">{target}</p>
        </div>
        <div className="w-100 ms-2">
          <StatsChart color={getColor(color)} data={data} />
        </div>
      </Flex>
    </>
  );
};

const PrintWorkInvoicing = () => {
  const statsData = [
    {
      id: 1,
      title: 'Print Verzoeken (per week)',
      amount: 15,
      target: '100 vs 115',
      icon: 'print',
      caret: 'caret-up',
      color: 'warning',
      caretColor: 'success',
      data: [130, 120, 110, 100, 90, 80, 100, 115]
    },
    {
      id: 2,
      title: "Pagina's Geprint (per week)",
      amount: 20,
      target: '300 vs 360',
      icon: 'print',
      caret: 'caret-up',
      color: 'info',
      caretColor: 'success',
      data: [360, 330, 400, 325, 340, 290, 300, 360]
    },
    {
      id: 3,
      title: 'Leerlingen Gefactuureerd (per week)',
      amount: 10,
      target: '220 vs 200',
      icon: 'print',
      caret: 'caret-down',
      color: 'success',
      caretColor: 'danger',
      data: [300, 280, 270, 280, 250, 240, 220, 200]
    }
  ];

  const printsPerEmployee = [
    {
      id: 1,
      owner: 'John oliver',
      printRequests: 30,
      totalPages: 300,
      totalStudents: 200
    },
    {
      id: 2,
      owner: 'Sean Paul',
      printRequests: 25,
      totalPages: 250,
      totalStudents: 175
    },
    {
      id: 3,
      owner: 'Brad Shaw',
      printRequests: 20,
      totalPages: 200,
      totalStudents: 150
    },
    {
      id: 4,
      owner: 'Max Payne',
      printRequests: 15,
      totalPages: 150,
      totalStudents: 125
    },
    {
      id: 5,
      owner: 'Max Payne',
      printRequests: 10,
      totalPages: 100,
      totalStudents: 100
    }
  ];

  const [stats] = useState(statsData);
  return (
    <>
      <Row className="g-3 mb-3">
        <Col xxl={12}>
          <Card>
            <Card.Body>
              <Row>
                {stats.map((stat, index) => (
                  <Col
                    lg={4}
                    key={stat.id}
                    className={classNames({
                      'border-bottom border-lg-0 border-lg-end':
                        index !== stats.length - 1,
                      'pb-3 pb-lg-0': index === 0,
                      'py-3 py-lg-0': index === 1,
                      'pt-3 pt-lg-0': index === 2
                    })}
                  >
                    <StatsItem stat={stat} />
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col xxl={6}>
          <Card className="overflow-hidden">
            <FalconCardHeader title="Prints per medewerker" titleTag="h6" />
            <Card.Body className="p-0">
              <DealForeCastTable data={printsPerEmployee} />
            </Card.Body>
          </Card>
        </Col>
        <Col xxl={6}>
          <Card className="h-100">
            <FalconCardHeader title="Print Verzoeken (per dag)" titleTag="h6" />
            <Card.Body className="pb-0">
              <TrafficSourceChart />
            </Card.Body>
            <Card.Footer />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PrintWorkInvoicing;
