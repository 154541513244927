import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import StudentService from 'services/student.service';
import StudentInformation from './components/StudentInformation';

const Student = () => {
  const { id } = useParams();
  const [student, setStudent] = useState(); //State  = variabele op pagina

  useEffect(() => {
    StudentService.getStudent(id).then(data => {
      console.log(data);
      setStudent(data);
    });
  }, []);

  return (
    <>
      {id && student && (
        <>
          <StudentInformation studentObject={student} />
        </>
      )}
    </>
  );
};

export default Student;
