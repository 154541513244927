import React from 'react';
import { Form } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';
import Button from 'react-bootstrap/Button';
import SchoolService from 'services/school.service';
import { useHistory } from 'react-router-dom';
import { Card } from 'react-bootstrap';

const SchoolInformation = ({ schoolObject }) => {
  const [school, setSchool] = useState(schoolObject ? schoolObject : {});
  const [validated, setValidated] = useState(false);

  const history = useHistory();

  const onChangeName = e => {
    setSchool({
      ...school,
      name: e.target.value
    });
  };

  const onChangeStreet = e => {
    setSchool({
      ...school,
      street: e.target.value
    });
  };

  const onChangeStreetNr = e => {
    setSchool({
      ...school,
      streetNr: e.target.value
    });
  };

  const onChangePostalCode = e => {
    setSchool({
      ...school,
      postalCode: e.target.value
    });
  };

  const onChangeCommunity = e => {
    setSchool({
      ...school,
      community: e.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    setValidated(true);

    SchoolService.registerOrUpdateSchool(school).then(
      data => {
        history.push('/beheer/school/' + data.id);
        window.location.reload();
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.error) ||
          error.error ||
          error.toString();
        console.log(resMessage);
      }
    );
  };

  const deleteSchool = () => {
    SchoolService.deleteSchool(school.id).then(
      () => {
        history.push('/beheer/schoolboard');
        window.location.reload();
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.error) ||
          error.error ||
          error.toString();
        console.log(resMessage);
      }
    );
  };

  return (
    <>
      <FalconComponentCard>
        <FalconComponentCard.Header className="border-bottom" noPreview>
          <Flex justifyContent="between" alignItems="center">
            <h5 className="mb-0 hover-actions-trigger" id="geplandDrukwerk">
              School Gegevens
            </h5>
          </Flex>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="name">
                <Form.Label>Naam</Form.Label>
                <Form.Control
                  value={school.name}
                  type="text"
                  required
                  onChange={onChangeName}
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een naam op te geven
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Card.Title as="div">Adres</Card.Title>
            <Row className="mb-3 g-3">
              <Form.Group as={Col}>
                <Form.Label>Straat</Form.Label>
                <Form.Control
                  value={school.street}
                  type="text"
                  required
                  onChange={onChangeStreet}
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een straat op te geven
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Huisnummer</Form.Label>
                <Form.Control
                  value={school.streetNr}
                  type="text"
                  required
                  onChange={onChangeStreetNr}
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een huisnummer op te geven
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Postcode</Form.Label>
                <Form.Control
                  value={school.postalCode}
                  type="text"
                  required
                  onChange={onChangePostalCode}
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een postcode op te geven
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Gemeente</Form.Label>
                <Form.Control
                  value={school.community}
                  type="text"
                  required
                  onChange={onChangeCommunity}
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een gemeente op te geven
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Form.Group>
              <Flex justifyContent="start" alignItems="center">
                <Button type="submit" color="primary" className="mt-3 me-3">
                  {school.id ? 'Save Changes' : 'Maak School'}
                </Button>
                <Button
                  variant="danger"
                  className="mt-3"
                  onClick={() => deleteSchool()}
                >
                  Verwijderen
                </Button>
              </Flex>
            </Form.Group>
          </Form>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default SchoolInformation;
