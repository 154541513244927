/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Form, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { useTable, useSortBy, usePagination, useRowSelect } from 'react-table';
import Avatar from 'components/common/Avatar';
import SoftBadge from 'components/common/SoftBadge';
import IconItem from 'components/common/icon/IconItem';
import CardDropdown from 'components/common/CardDropdown';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();

    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Form.Check type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const AdvanceTable = ({ columns, data }) => {
  const { getTableProps, headers, page, prepareRow } = useTable(
    {
      columns,
      data
    },
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          headerProps: {
            style: {
              maxWidth: 30
            }
          },
          cellProps: {
            style: {
              maxWidth: 30
            }
          },
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          )
        },
        ...columns
      ]);
    }
  );

  return (
    <div className="mx-ncard">
      <SimpleBarReact>
        <Table className="fs--1 mb-0 overflow-hidden" {...getTableProps()}>
          <thead className="bg-200 text-800">
            <tr>
              {headers.map((column, index) => (
                <th
                  key={index}
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({
                      ...column.headerProps,
                      className: classNames('text-nowrap py-3', {
                        [column.headerProps?.className]:
                          column.headerProps?.className
                      })
                    })
                  )}
                >
                  {column.render('Header')}
                  {column.canSort ? (
                    column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="sort desc" />
                      ) : (
                        <span className="sort asc" />
                      )
                    ) : (
                      <span className="sort" />
                    )
                  ) : (
                    ''
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  key={i}
                  className="hover-actions-trigger btn-reveal-trigger hover-bg-100"
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        {...cell.getCellProps({
                          ...cell.column.cellProps,
                          className: classNames(
                            'align-middle white-space-nowrap',
                            {
                              [cell.column.cellProps?.className]:
                                cell.column.cellProps?.className
                            }
                          )
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </SimpleBarReact>
    </div>
  );
};

const RecentLeadsTable = ({ data }) => {
  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
      Cell: rowData => {
        return (
          <Link to="#!">
            <Flex alignItems="center">
              <Avatar size="xl" src={rowData.row.original.img} />
              <h6 className="mb-0 ps-2 text-800">
                {rowData.row.original.name}
              </h6>
            </Flex>
          </Link>
        );
      }
    },
    {
      accessor: 'email',
      Header: 'Email and Phone',
      Cell: rowData => {
        return (
          <a href={`mailto:${rowData.row.original.email}`} className="mb-0">
            {rowData.row.original.email}
          </a>
        );
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: rowData => {
        return (
          <SoftBadge pill bg={rowData.row.original.variant} className="me-2">
            {rowData.row.original.status}
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'Action',
      Header: 'Action',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      },
      disableSortBy: true,
      Cell: () => (
        <div>
          <div className="hover-actions bg-100">
            <IconItem
              tag="button"
              icon={['far', 'edit']}
              size="sm"
              className="btn rounded-3 me-2 fs--2"
            />
            <IconItem
              tag="button"
              icon={['far', 'comment']}
              size="sm"
              className="btn rounded-3 me-2 fs--2"
            />
          </div>
          <CardDropdown btnRevealClass="btn-reveal-sm" drop="start" />
        </div>
      )
    }
  ];
  return <AdvanceTable data={data} columns={columns} />;
};

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool
};

AdvanceTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      img: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      variant: PropTypes.string.isRequired
    })
  )
};

RecentLeadsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      img: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      variant: PropTypes.string.isRequired
    })
  )
};

export default RecentLeadsTable;
